import "styles/pages/blog.scss"

import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "components/layout"
import Seo from "components/seo"
import PageHeader from "components/PageHeader/PageHeader"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"

const Blog = ({ data }) => {
  const title = "Tíđindi"

  return (
    <Layout>
      <Seo title={title} />
      <PageHeader title={title} />
      <Breadcrumbs title={title} />

      <section className="blog">
        <div className="container-fluid">
          {data.allWpPost.nodes.slice(0, 1).map((item, index) => (
            <div className="blog-tile-main" key={index}>
              <div className="row g-0">
                <div className="col-xl-4 col-md-6">
                  <div className="blog-tile-main__inner">
                    <div>
                      <time>
                        {item.date.split("T")[0].split("-").reverse().join(".")}
                      </time>
                      <h2>{item.title}</h2>
                      <div dangerouslySetInnerHTML={{ __html: item.excerpt }} />
                    </div>
                    <Link to={item.uri}>Síggj meira</Link>
                  </div>
                </div>
                <div className="col-xl-8 col-md-6">
                  <div
                    className="blog-tile-main__thumb"
                    style={{
                      backgroundImage: `url('${item?.featuredImage?.node?.sourceUrl}')`,
                    }}
                  >
                    <span>Nýggjasta</span>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div className="row">
            {data.allWpPost.nodes.slice(1).map((item, index) => (
              <div className="col-md-6" key={index}>
                <div className="blog-tile">
                  <div
                    className="blog-tile__thumb"
                    style={{
                      backgroundImage: `url('${item?.featuredImage?.node?.sourceUrl}')`,
                    }}
                  ></div>
                  <div className="blog-tile__inner">
                    <div>
                      <h2>{item.title}</h2>
                      <div dangerouslySetInnerHTML={{ __html: item.excerpt }} />
                    </div>
                    <Link to={item.uri}>Síggj meira</Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpPost(sort: { fields: date, order: DESC }) {
      nodes {
        id
        title
        uri
        date
        excerpt
        featuredImage {
          node {
            sourceUrl
          }
        }
      }
    }
  }
`

export default Blog
